import Axios from 'axios';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { default_server } from '../services/api';

import { storage } from '../enums';
import { usePersistedState } from '../hooks';
import { askUserPermission } from '../push-notification';
import {
  isMobile,
  isPWAInstaled,
  isFirebaseSupported,
  isSafariNotifficationSupported,
 } from '../utils/device';
import { playSong } from '../utils/song';
import songsName from '../enums/songsName';

const Context = createContext({});

function PushContextProvider({ children }){
  const [showPushCount, setShowPushCount] = usePersistedState(storage.showPushCount, isMobile && !isPWAInstaled ? 6 : 0);
  const [enabledInitCount, setEnabledInitCount] = usePersistedState(storage.showPushInfoAlert, false);
  const [showPushModal, setShowPushModal] = useState(false);

  const isSigned = useMemo(() => {
    return !!window.localStorage.getItem('@cov/hash');
  }, []);

  useEffect(() => {
    /**
     * Só será iniciado a contagem para exibir o modal de push caso o enabledInitCount estejá true ou se o PWA estiver instalado.
     */
    if((isMobile && enabledInitCount) || (!isMobile && isSigned)) {
      /**
       * Sempre que estiver no 0 o modal será exibido.
       * A contagem máxima é de 3 atualizações.
       */
      if(showPushCount >= 5) {
        setShowPushCount(0);
      } else {
        setShowPushCount(oldShowPushCount => oldShowPushCount + 1);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initCountToShowNotificationModal = useCallback(() => {
    setEnabledInitCount(true);
  }, [setEnabledInitCount]);

  const stopCountToShowNotificationModal = useCallback(() => {
    setEnabledInitCount(false);
  }, [setEnabledInitCount]);

  function showNotificationModal() {
    setShowPushModal(true);
  }

  function dismissNotificationModal() {
    setShowPushModal(false);

    setShowPushCount(oldShowPushCount => oldShowPushCount + 1);
  }

  /**
   * Inicia ou para a exibição do modal de push notiofication baseado no timer.
   * Quando o usuário entrar em STANDALONE quer dizer que ele está acessando via PWA então permite o timer.
   * Caso contrario nega. Será usuado em caso de desinstalar o PWA.
   */
  useEffect(() => {
    if(isPWAInstaled && isSigned) {
      initCountToShowNotificationModal();
    } else {
      stopCountToShowNotificationModal();
    }
  }, [initCountToShowNotificationModal, isSigned, stopCountToShowNotificationModal]);

  /**
   * Envia para o backend as informações desse usuário, habilitando ele para receber as notificações.
   */
  function registerDeviceForReceivePushNotification({ hash, isIOSDevice = false }) {
    const user_token = window.localStorage.getItem(storage.hash);

		if(hash && user_token) {
			const data = {
				covtoken: user_token,
				token: hash,
				produto: 'L',
				ios: isIOSDevice ? 1 : 0
			};

			Axios.post(`${default_server}/cadastrauserpush`, data);
		}
  }

  /**
   * Trata as permissões do IOS, por padrão ele vai pedir permissão.
   *
   * OBS: atenção que caso ele não exiba o popup nativo do sistema é muito possível que haja informações
   * erradas no front ou no backend.
   */
  function handleRequestSafariPermission(data) {
    switch(data?.permission) {
      case 'granted':
				console.log('>> Push notification permission granted', data.deviceToken);

        registerDeviceForReceivePushNotification({ hash: data.deviceToken, isIOSDevice: true });

        break;
      case 'denied':
				console.log('>> Push notification permission denied', data.deviceToken);

        break;
      default:
        window.safari.pushNotification.requestPermission(
					'https://dev.cov.n3r.com.br',
					'web.br.com.n3r.cov.dev',
					{},
					handleRequestSafariPermission
				);

        break;
    }
  }

  /**
   * Trata as permissões dos demais browsers que suportam receber as notificações.
   */
  function handleRequestFirebasePermission() {
    askUserPermission().then(hash => {
			registerDeviceForReceivePushNotification({ hash });
		})
  }

  function handleAcceptReceivePushNotification() {
    playSong(songsName.TAP)
    dismissNotificationModal();

    if(isSafariNotifficationSupported) handleRequestSafariPermission();

    if(isFirebaseSupported) handleRequestFirebasePermission();
  }

  function handleRejectReceivePushNotification() {
    playSong(songsName.TAP)
    dismissNotificationModal();
  }

  const verifyPushNotificationCurrentPermissionSafari = useCallback(() => {
    const permissionData = window.safari.pushNotification.permission('web.br.com.n3r.cov.dev')

    return permissionData.permission
  }, []);

  const verifyPushNotificationCurrentPermissionFirebase = useCallback(() => {
    return window.Notification.permission;
  }, []);

  const verifyIfEnabledPushNotification = useCallback(() => {
    if(isSafariNotifficationSupported) return verifyPushNotificationCurrentPermissionSafari();

    if(isFirebaseSupported) return verifyPushNotificationCurrentPermissionFirebase();

    // Por padrão retorna não suportado em caso muito excepcionais
    return 'unsupported';
  }, [verifyPushNotificationCurrentPermissionFirebase, verifyPushNotificationCurrentPermissionSafari]);

  /**
   * Exibe o modal de push notification quando o timer estiver no 0, se o usuário ainda não tiver permtido.
   */
  useEffect(() => {
    if(showPushCount === 0) {
      const permission = verifyIfEnabledPushNotification();

      console.log({ permission });

      if(permission === 'default') {
        showNotificationModal();
      }
    }
  }, [showPushCount, verifyIfEnabledPushNotification]);

  return (
    <Context.Provider
      value={{
        showPushModal,
        showNotificationModal,
        acceptPushNotification: handleAcceptReceivePushNotification,
        rejectPushNotification: handleRejectReceivePushNotification,
        verifyIfEnabledPushNotification,
      }}>
        {children}
    </Context.Provider>
  )
}

export function usePushContext() {
  const context = useContext(Context);

  if(!context) throw new Error('Should be use the usePushContext inside of PushContextProvider');

  return context;
}

export default PushContextProvider;
