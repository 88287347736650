export default {
  BUBBLES: 'bubbles',
  DOWNLOAD: 'download',
  ACEAN_WAVES: 'acean_waves',
  SEAGULLS: 'seagulls',
  TAP: 'tap',
  MAIN_BACKGROUND: 'main_background',
  PAGE_FLIP: 'page_flip',
  OPEN_GAME: 'open_game',
  CLOSE_GAME: 'close_game',
}