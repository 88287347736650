import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.css';
import App from './App';
import * as serviceWorker from './services/serviceWorker';
import firebase from 'firebase/app';

import { isMobile } from './utils/device'

import PushContextProvider from './context/pushContext';
import PWAContextProvider from './context/pwaContext';

if (firebase.messaging.isSupported()) {
	const firebaseConfig = {
		apiKey: "AIzaSyAEonBu86N733AyeiMpDOv_kVWF49hJYXo",
		authDomain: "cov-producao.firebaseapp.com",
		projectId: "cov-producao",
		storageBucket: "cov-producao.appspot.com",
		messagingSenderId: "353661224917",
		appId: "1:353661224917:web:7249f6ba84b78a23ca1f5c",
		measurementId: "G-08P9WKP0K9"
	};
	
	firebase.initializeApp(firebaseConfig);
	firebase.analytics();

	const messaging = firebase.messaging();
	messaging.onMessage(function(payload) {
		if(payload?.data?.title && payload?.data?.body) {
			const { title, body } = payload?.data;

			const notification_options = {
				body,
				icon: 'https://dev.cov.n3r.com.br/logo512.png',
			}
	
			if(isMobile) {
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					if(registrations) {
						registrations[0].showNotification(title, notification_options);
					}
				});
			} else {
				new Notification(title, notification_options);
			}
		}

	});
}


ReactDOM.render(
	<PushContextProvider>
		<PWAContextProvider>
			<App />
		</PWAContextProvider>
	</PushContextProvider>
, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
