import React, { Component } from "react";
import TweenOne from "rc-tween-one";
import "./../../assets/styles/Preloader.css";
import pngLoader from '../../assets/images/anim_logo_boat_texto_novo_loop.gif';
import { playSong, stopSong } from "../../utils/song";
import songsName from "../../enums/songsName";

class SimpleLoader extends Component {
  static defaultProps = {
    appState: null
  };

  state = {
    isAppInitialized: false
  };

  componentDidUpdate() {
    if (this.props.appState === 'inicializing' || this.props.appState === 'loading') {
      playSong(songsName.ACEAN_WAVES)
    }
    
    if (this.props.appState !== 'inicializing' && this.props.appState !== 'loading') {
      stopSong(songsName.ACEAN_WAVES)
    }
  }

  render() {
    const screenWidth = window.innerWidth;
    const stateAppLoaded =
      this.props.appState === "inicializing" ||
      this.props.appState === "loading"
        ? false
        : true;

    if (this.state.isAppInitialized) {
      return null;
    }
    return (
      <>
        <TweenOne
          className="preloader_animate"
          paused={!stateAppLoaded}
          animation={{
            opacity: 0,
            onComplete: () => {
              this.setState({
                isAppInitialized: true
              })
            }
          }}>
          <img src={pngLoader} alt="Animated logo" />
        </TweenOne>
      </>
    );
  }
}

export default SimpleLoader;
