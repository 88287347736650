import React from 'react';
import pngLoader from './../../assets/images/anim_404.gif';
import './../../assets/styles/Error404.css';

const Error404 = (props) => {
	if (props.appState !== 'error404') {
		return null;
	}

	let locationUrl = window.location.host;
	if (locationUrl.indexOf('http') < 0) {
		// for 'localhost' case example
		locationUrl = `http://${window.location.host}`;
	}

	setTimeout(() => {
		window.location.replace(locationUrl);
	}, 1500);

	window.document.getElementById('App').style.backgroundColor = '#005b86';

	return (
		<div className="error404_container">
			<img src={pngLoader} alt={'Reiniciando...'} />
		</div>
	);
};

export default Error404;
