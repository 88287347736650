import React, { useState, useEffect, useRef } from 'react';
import { default_server } from '../../services/api';
import "./../../assets/styles/Section.css"


const Section = props => {

    const axios = require('axios');

    const appState = props.parent.appState || null;
    const ref = useRef(null);
    const [payload, updatePayload] = useState(props.parent.payload);
    const [currentBookID, setCurrentSlideId] = useState(0);

    const passPropsToChildren = (element, props) => {
        if (!React.isValidElement(element)) return element;
    
        // Clone the current element and pass the props
        return React.cloneElement(element, props, 
            React.Children.map(element.props.children, child => 
                passPropsToChildren(child, props) // Recursive call for nested children
            )
        );
    };

    const childrens = props.children.map((child, idx) => 
        passPropsToChildren(child, {
            key: idx,
            payload: payload,
            appState: appState,
            currentBookID: currentBookID,
            onSlideChange: slideIdx => setCurrentSlideId(slideIdx),
            onSurpriseComplete: () => props.parent.changeState(),
            onBoatComplete: () => props.parent.changeToLandingPage(),
        })
    );

    useEffect(() => {

        if (currentBookID === 0) {
            return;
        }

        for (let i = 0; i < payload.listData.length; i++) {
            const element = payload.listData[i];
            if (element.bookId === currentBookID) {
                if ('bookDownloadFile' in element) {
                    return;
                }
            }
        }

        // axios.post('http://www.mocky.io/v2/5e3b77d63000007700214595', 
        axios.post(`${default_server}/getlivro/`,
            {
                "token": payload.token,
                "bookId": `${currentBookID}`
            },
            {
                timeout: 20000,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(success => {
                const newData = success.data;
                const updatedList = payload.listData.map((item, index) => {
                    if (item.bookId === currentBookID) {
                        return {
                            ...newData,
                            ...item
                        }
                    }
                    else {
                        return {
                            ...item
                        }
                    }
                });
                updatePayload({
                    ...payload,
                    listData: updatedList
                });
            })
            .catch(error => {
                console.log(error);
            });

    }, [axios, currentBookID, payload]);

    return (
        <section ref={ref}>
            <div className={`section_background ${props.parent.appState === 'landingpage' ? 'section_background_landingpage' : ''}`}>
                <div id="frontground" className={`section_frontground ${props.parent.appState === 'landingpage' ? 'section_backgrounds' : 'section_bgColor'}`}>
                    {childrens}
                </div>
            </div>
        </section>
    );
}

export default Section;
