import React, { useEffect } from "react";
import { ErrorMessage, Formik } from "formik";
import { default_server } from './../../../services/api';
import * as yup from "yup";
import InputMask from "react-input-mask";
import preLoader from "../../../assets/images/preloader_button.gif";
import success from "../../../assets/images/success.png";
import "./faleconosco-form.css";
import { playSong } from '../../../utils/song'
import songsName from "../../../enums/songsName";

const validationSchema = yup.object().shape({
  name: yup.string().required("digite seu nome"),
  email: yup
    .string()
    .matches(
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
      "e-mail não válido"
    )
    .required("digite seu e-mail"),
  msisdn: yup.string().required("digite seu celular"),
  message: yup.string().required("digite sua mensagem")
})

const Form = props => {
  const {
    toggle_callus,
    isLoading,
    isSuccess,
    setIsSuccess,
  } = props;
  

  useEffect(() => {
    return () => {
      setIsSuccess(false);
    };
  }, [setIsSuccess]);

  const SEND = isLoading ? (
    <img src={preLoader} alt={"Loading..."} style={{ height: "25px" }} />
  ) : isSuccess ? (
    <img src={success} alt={"Success"} style={{ height: "25px" }} />
  ) : (
    "ENVIAR"
  );

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        msisdn: "",
        message: ""
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const {
          name,
          email,
          msisdn,
          message
        } = values;
    
        props.setIsLoading(true);
    
        const axios = require('axios');
        axios.post(`${default_server}/email`, {
          "msisdn": `55${msisdn.replace(/[^a-zA-Z0-9]/g, "")}`,
          "email": email,
          "mensagem": message,
          "nome": name,
          "produto": "L"
        })
        .then(function (response) {
          
          if ('data' in response) {
            if ('returncode' in response.data) {
              if (response.data.returncode === '200') {
                setTimeout(() => {
                  props.setIsLoading(false);
                  props.setIsSuccess(true);
                  setSubmitting(false);
                }, 1000);
                setTimeout(() => {
                  props.toggle_callus(false);
                }, 2300);
              }
              else {
                throw `Error ${response.data.returncode}`
              }
            }
            else {
              throw 'missing "returncode" object'
            }
          }
          else {
            throw 'missing "data" object'
          }
    
        })
        .catch(error => {
          setTimeout(() => {
            props.setIsLoading(false);
            props.setIsSuccess(false);
          }, 600);
        });
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="fale_conosco_container">
            <input
              placeholder="Nome"
              type="text"
              value={values.name}
              onChange={handleChange("name")}
            />
            <ErrorMessage component="span" name="name" />
            <input
              placeholder="E-mail"
              type="text"
              value={values.email}
              onChange={handleChange("email")}
              name="email"
            />
            <ErrorMessage component="span" name="email" />
            <InputMask
              type="tel"
              name="msisdn"
              mask="(99) 99999-9999"
              placeholder="Número do celular com DDD"
              maskChar={null}
              value={values.msisdn}
              onChange={handleChange("msisdn")}
            />
            <ErrorMessage component="span" name="msisdn" />
            <textarea
              type="text"
              value={values.message}
              onChange={handleChange("message")}
              placeholder="Mensagem"
            />
            <ErrorMessage component="span" name="message" />
          </div>
          <div className="modal-button-container">
            <button
              onMouseEnter={() => {
                playSong(songsName.TAP)
              }}
              onClick={() => {
                playSong(songsName.TAP)

                toggle_callus(false)
              }}
            >
              FECHAR
            </button>
            <button
              onMouseEnter={() => {
                playSong(songsName.TAP)
              }}
              type="submit"
            >
              {SEND}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Form;
