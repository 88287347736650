import React, { useState, useEffect, useCallback, useRef } from "react";
import TweenOne from "rc-tween-one";
import FlippingPages from "flipping-pages";
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import "flipping-pages/FlippingPages.css";
import "./../../assets/styles/ReadBook.css";
import flipSound from "../../assets/mp3/page-flip.mp3"
import { playSong } from '../../utils/song'
import DeviceOrientation from "./DeviceOrientation";
import AudioButton from "../audio-button";
import { FullscreenAlert } from "./FullscreenAlert";
import { isIOSDevice, isMobile } from "../../utils/device";
import songsName from "../../enums/songsName";

const Reader = (props) => {
  const lastClickXPositionRef = useRef(null) // última posição que o user clickou (eixo X)

  const [selected, setSelected] = useState(0);
  const [alert, setAlert] = useState(false);
  const [fullscreenAlert, setFullscreenAlert] = useState(false);
  const [isLandscape, setLandscape] = useState(false);
  const [readerWidth, setReaderWidth] = useState(100);
  const [readerHeight, setReaderHeight] = useState(100);

  const book = props.reader;
  const backgroundColor = "#99999900"; // TODO: Levar essas variaveis para o CSS
  const backgroundColor2 = "#CCCCCC00"; // para ser usada como variavel no CSS


  const pages_landscape = [
    // mock temp
    { id: 0, image: "https://i.ibb.co/jr5RjYf/Capa.jpg" },
    { id: 1, image: "https://i.ibb.co/Rh7Sd54/img01.jpg" },
    { id: 2, image: "https://i.ibb.co/RgR6DTD/img02.jpg" },
    { id: 3, image: "https://i.ibb.co/MfS5RDX/img03.jpg" },
    { id: 4, image: "https://i.ibb.co/ZBhN6WH/img04.png" },
    { id: 5, image: "https://i.ibb.co/MBHDYgT/img05.png" },
    { id: 6, image: "https://i.ibb.co/smjyfcJ/img06.png" },
    { id: 7, image: "https://i.ibb.co/dLXNWQQ/img07.png" },
    { id: 8, image: "https://i.ibb.co/852mYMQ/img08.png" },
    { id: 9, image: "https://i.ibb.co/Th41f8z/img09.png" },
    { id: 10, image: "https://i.ibb.co/4VHPwSM/img10.png" },
    { id: 11, image: "https://i.ibb.co/31KfnKk/img11.png" },
    { id: 12, image: "https://i.ibb.co/yRWZHPq/img12.png" },
    { id: 13, image: "https://i.ibb.co/ZBpdZcK/img13.png" },
    { id: 14, image: "https://i.ibb.co/3dM9GK8/img14.png" },
    { id: 15, image: "https://i.ibb.co/6vCD2g3/img15.png" },
    { id: 16, image: "https://i.ibb.co/z68FLgW/img16.png" },
    { id: 17, image: "https://i.ibb.co/bzZCn9d/img17.png" },
    { id: 18, image: "https://i.ibb.co/NxZ4J62/img18.png" },
    { id: 19, image: "https://i.ibb.co/cYYdZV2/img19.png" },
  ];
  const pages_portrait = [
    // mock temp
    { id: 0, image: "https://i.ibb.co/h896c9f/Capa.jpg" },
    { id: 1, image: "https://i.ibb.co/yFmMDKc/img01.jpg" },
    { id: 2, image: "https://i.ibb.co/hcmTBbR/img02.jpg" },
    { id: 3, image: "https://i.ibb.co/tPL5LnQ/img03.jpg" },
    { id: 4, image: "https://i.ibb.co/2hVZLtw/img04.jpg" },
    { id: 5, image: "https://i.ibb.co/jk5PGGx/img05.jpg" },
    { id: 6, image: "https://i.ibb.co/Y0tZwh0/img06.jpg" },
    { id: 7, image: "https://i.ibb.co/kXK9Gr9/img07.jpg" },
    { id: 8, image: "https://i.ibb.co/C7QzBvw/img08.jpg" },
    { id: 9, image: "https://i.ibb.co/CKd5Nk4/img09.jpg" },
    { id: 10, image: "https://i.ibb.co/1qXcQrD/img10.jpg" },
    { id: 11, image: "https://i.ibb.co/FwQ75SY/img11.jpg" },
    { id: 12, image: "https://i.ibb.co/gz8WX2j/img12.jpg" },
    { id: 13, image: "https://i.ibb.co/XsxyNF3/img13.jpg" },
    { id: 14, image: "https://i.ibb.co/qgYJ3QX/img14.jpg" },
    { id: 15, image: "https://i.ibb.co/k2FHvX4/img15.jpg" },
    { id: 16, image: "https://i.ibb.co/7N19js7/img16.jpg" },
    { id: 17, image: "https://i.ibb.co/cb0ZTP5/img17.jpg" },
    { id: 18, image: "https://i.ibb.co/xJw55LN/img18.jpg" },
    { id: 19, image: "https://i.ibb.co/bPGhSRW/img19.jpg" },
    { id: 20, image: "https://i.ibb.co/bPGhSRW/img20.jpg" },
    { id: 21, image: "https://i.ibb.co/qyLtvRS/img21.jpg" },
    { id: 22, image: "https://i.ibb.co/br0xtFk/img22.jpg" },
    { id: 23, image: "https://i.ibb.co/C0MW6mv/img23.jpg" },
    { id: 24, image: "https://i.ibb.co/Hgv6ZjN/img24.jpg" },
    { id: 25, image: "https://i.ibb.co/Fw9N1c9/img25.jpg" },
    { id: 26, image: "https://i.ibb.co/3Myzwzm/img26.jpg" },
    { id: 27, image: "https://i.ibb.co/KLs28Nq/img27.jpg" },
    { id: 28, image: "https://i.ibb.co/sPd7htY/img28.jpg" },
  ];

  function playFlipSound(){
    const audio = document.getElementById("page-flip");
    audio.currentTime = 0;
    audio.play();
  }

  function handleClose() {
    setSelected(0);
    props.changeReader();
  }

  function handleSelectedChange(selected) {
    setSelected(selected);
  }

  function previous() {
    playFlipSound()
    setSelected(selected - 1);
  }

  function next() {
   playFlipSound()
    setSelected(selected + 1);

  }

  function handleResize() {
    if (book === false) return;
    const spaceButtons = 50;
    const spaceHeader = 0;
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;
    const isLandscape = book.isLandscape;
    // atualiza dimensões flip-book
    const h = innerHeight - spaceHeader;
    setReaderWidth(regra3(h));
    setReaderHeight(h);
    // verifica se landscape está correto
    if (isLandscape) {
      setLandscape(true);
      // atualiza dimensões flip-book
      // const w = innerWidth-spaceButtons;
      // setReaderWidth(w);
      // setReaderHeight(regra3(w));
      // console.log('altura:', regra3(w));
      if (innerWidth < innerHeight) {
        // mostra orientação
        setAlert(true);
      } else {
        // remove orientação
        setAlert(false);
      }
    }
    // verifica se portrait está correto
    else if (!isLandscape) {
      setLandscape(false);
      if (innerWidth > innerHeight) {
        // mostra orientação
        setAlert(true);
      } else {
        // remove orientação
        setAlert(false);
      }
    }
  }

  function regra3(input) {
    const a1 = 1006;
    const b1 = 2010;
    const a2 = input;
    const b2 = (a2 * b1) / a1;
    return b2;
  }

  const handleShowFullscreenAlert = () => {
    setFullscreenAlert(true);
    setTimeout(() => setFullscreenAlert(false), 6000);
  };

  useEffect(() => {
    window.addEventListener("resize", () => handleResize());
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener("visibilitychange", () => {
      if(document.visibilityState === 'visible' && isMobile && !isIOSDevice) {
        const interval = setInterval(() => {
          setTimeout(() => {
            if((window.innerWidth >= window.innerHeight) && !document.fullscreenElement) {
              handleShowFullscreenAlert();
              clearInterval(interval);
            }
          }, 100);
        }, 200);
      }
    })
  }, []);

  // MONTAGEM DO ARRAY DE IMAGENS
  const num_pages = book.num_pages || 0; // 0 = mock
  const path = book.path || "https://..../";
  const prefix = book.prefix || "img_";
  const type = book.type || ".jpg";
  const array_from_api = [];
  for (let i = 0; i < num_pages; i++) {
    array_from_api.push({
      id: i,
      image: `${path}${prefix}${i < 10 ? "0" : ""}${i}.${type}`,
    });
  }
  const IMAGES_TO_SHOW =
    array_from_api.length === 0
      ? book.order === 1
        ? pages_portrait
        : pages_landscape
      : array_from_api
    ;

  useEffect(() => {
    handleResize();
  }, [book, handleResize]);

  const canNext = selected + 1 !== num_pages
  const canPrevious = selected > 0

  const onMouseDown = useCallback(e => {
    const currentXPosition = e.clientX || e.changedTouches[0].pageX

    lastClickXPositionRef.current = currentXPosition
  }, [])

  const onMouseUp = useCallback(e => {
    const currentXPosition = e.clientX || e.changedTouches[0].pageX

    const diffOfLastXPosition = lastClickXPositionRef.current - currentXPosition

    const isRight = diffOfLastXPosition >= 50
    const isLeft = diffOfLastXPosition <= -50

    console.log({canNext, canPrevious, isRight, isLeft});
    isRight && canNext && next()
    isLeft && canPrevious && previous()

    lastClickXPositionRef.current = null
  }, [canNext, canPrevious, next, previous])

  useEffect(() => {
    if(!book) return 

    document.addEventListener('touchstart', onMouseDown)
    document.addEventListener('touchend', onMouseUp)
    document.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('touchstart', onMouseDown)
      document.removeEventListener('touchend', onMouseUp)
      document.removeEventListener('mousedown', onMouseDown)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [onMouseDown, onMouseUp, book])

  if (!book) {
    return null;
  }

  return (
    <TweenOne style={{ opacity: 0 }} animation={{ opacity: 1 }}>
      <audio src={flipSound} id= "page-flip"></audio>
      <div className="reader_container">
        <div className="reader_flip_pages_container">

          <FlippingPages
            className="flip_pages_wrapper"
            style={{
              width: readerWidth,
              height: readerHeight,
            }}
            direction="horizontal"
            selected={selected}
            onSelectedChange={handleSelectedChange}
            touch-action="none"
          >
            {IMAGES_TO_SHOW.map((page, index) => (
              <div
                key={index.toString()}
                className="flip_page"
                style={{
                  background: `url(${page.image}) no-repeat center`,
                  backgroundSize: "contain",
                }}
              />
            ))}
          </FlippingPages>

          <div style={{
            width: readerWidth,
            height: readerHeight,
            backgroundColor: '#FFFFFF00',
            position: 'fixed'
          }} />

          <button
            onClick={next}
            disabled={!canNext}
            className="reader_wrapper_controller_right"
          >
            <MdKeyboardArrowRight size={40} color="#000000AA" />
          </button>

          <button
            onClick={previous}
            disabled={!canPrevious}
            className="reader_wrapper_controller_left"
          >
            <MdKeyboardArrowLeft size={40} color="#000000AA" />
          </button>

        </div>
        <div className="reader_wrapper">
          <div
            className="reader_background_header"
            style={{ backgroundColor: backgroundColor2 }}
          >
            <div
              className="reader_header"
              style={{ backgroundColor: backgroundColor }}
            >
              {/* <p
                className="reader_subtext_title"
                style={{ color: backgroundColor2 }}
              >
                Conta Outra Vez!
              </p>
              <p className="reader_subtext_book">
                {`${book.bookTitle} - ${book.bookAuthor}`}
              </p>
              <p className="reader_subtext_book_single">
                {`${book.bookTitle}`}
              </p> */}
            </div>
          </div>
        </div>
        {fullscreenAlert && <FullscreenAlert />}
      </div>
      <DeviceOrientation alert={alert} landscape={isLandscape} />
      <div className="render_pages_buttons" >
        <div className="render_pages_audioButtons">
          {book.bookAudio && !!book.bookAudio?.list[`${selected < 10 ? "0" : ""}${selected}`]?.storyteller && <AudioButton
            type="story"
            url={`${book.bookAudio.path}${book.bookAudio?.list[`${selected < 10 ? "0" : ""}${selected}`].storyteller}`}
            key={selected}
          />}
          {book.bookAudio && book.bookAudio?.list[`${selected < 10 ? "0" : ""}${selected}`]?.fx &&
            book.bookAudio?.list[`${selected < 10 ? "0" : ""}${selected}`]?.fx.map((item, index) => {
              console.log(index)
              return (<AudioButton
                type="fx"
                key={item}
                fxIndex={index++}
                url={`${book.bookAudio.path}${item}`}
              />)
            })}
        </div>

        <button
          className="reader_pages_close"
          onMouseEnter={() => {
            playSong(songsName.TAP)
          }}
          onClick={() => {
            playSong(songsName.TAP)
            handleClose()
          }}
        >
          <MdClose size={40} color="#000000AA" />
        </button>
      </div>


    </TweenOne >
  );
};

export default Reader;
