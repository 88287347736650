import React from 'react';
import './../../assets/styles/AuthFormLogin.css';

const RadioButton = ({radioCheck, setRadio}) => {
	return (
		<div
			className="checkbox"
			style={{ backgroundColor: radioCheck ? 'unset' : 'white' }}
			onClick={() => setRadio(!radioCheck)}
		/>
	);
};

export default RadioButton;
